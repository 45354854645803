<template>
    <div class="currency">
        <div class="main-wr currency-container">
            <div class="currency-container-header ta-center">
              {{ $t('company.currency_title') }}
            </div>
            <div class="currency-container-block">
                <img class="img-btc" src="@/assets/images/CompanySupport/btc.png" alt="" />
                <img class="img-eth" src="@/assets/images/CompanySupport/eth.png" alt="" />
                <div class="currency-content">
                    <div class="currency-content-item">
                        <div class="currency-content-item-name ta-right">
                            <span>Bitcoin</span> (BTC)
                        </div>
                        <div class="currency-content-item-cost ta-right">
                            {{ btc }}
                        </div>
                    </div>
                    <div class="currency-content-item">
                        <div class="currency-content-item-name ta-right">
                            <span>Gold</span>(XAU)
                        </div>
                        <div class="currency-content-item-cost ta-right">
                            {{ gold }}
                        </div>
                    </div>
                    <div class="currency-content-item">
                        <div class="currency-content-item-name ta-right">
                            <span>British pound</span> (GBR)
                        </div>
                        <div class="currency-content-item-cost ta-right">
                            {{ pound }}
                        </div>
                    </div>
                    <div class="currency-content-item">
                        <div class="currency-content-item-name ta-right">
                            <span>Euro</span> (EUR)
                        </div>
                        <div class="currency-content-item-cost ta-right">
                            {{ euro }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'CurrentCurrency',
    data() {
        return {
            btc: '',
            gold: '',
            pound: '',
            euro: '',
        }
    },
    methods: {
        async fetchData() {
            try {
                const gold = await axios.get(
                    `https://www.goldapi.io/api/XAU/USD/20200617`,
                    {
                        headers: {
                            "x-access-token": "goldapi-1fngetlakwhjw0-io",
                        },
                        params: {},
                    }
                );
                const { data } = await axios.get("https://blockchain.info/ticker");
                let curBtc = data.USD.last.toString();
                let resBtc = "$" + curBtc[0] + curBtc[1] + "," + curBtc.substr(2);
                this.btc = resBtc;

            
                let curGold = gold.data.price.toString();
                let resGold = "$" + curGold[0] + "," + curGold.substr(1);
                this.gold = resGold;
            
                const response = await axios.get(
                    "https://api.tinkoff.ru/v1/currency_rates?from=EUR&to=USD"
                );
                this.euro = "$" + response.data.payload.rates[2].buy.toFixed(2);
            
                const response1 = await axios.get(
                    "https://api.tinkoff.ru/v1/currency_rates?from=GBP&to=USD"
                );
                this.pound = "$" + (response1.data.payload.rates[2].buy - 0.1).toFixed(2);
            } catch (err) {
                console.log(err);
            }
        }
    },
    mounted() {
        this.fetchData();
    },
}
</script>

<style lang="scss" scoped>
.currency {
  padding-bottom: 300px;
    &-container {
        margin-top: 110px;
        @media (max-width: 480px) {
            margin-top: 50px;
        }
        &-header {
            color: #09ECFB;
            font-size: 50px;
            line-height: 62px;
            letter-spacing: 0.08em;
            @media (max-width: 1600px) {
                font-size: 40px;
                line-height: 50px;
            }
            @media (max-width: 480px) {
                font-size: 18px;
                line-height: 22px;
                letter-spacing: 0.08em;
            }
        }
        justify-content: center;
        &-block {
            position: relative;
            display: flex;
            width: 1200px;
            margin: 70px auto 0 auto;
            @media (max-width: 1520px) {
                width: 1030px;
            }
            @media (max-width: 1400px) {
                width: 100%;
            }
            @media (max-width: 1100px) {
                width: 550px;
            }
            @media (max-width: 700px) {
                width: 360px;
            }
            @media (max-width: 480px) {
                width: 100%;
                margin-top: 30px;
            }
            .currency-content {
                background-blend-mode: overlay;
                border: 1.5px solid rgba(255, 255, 255, 0.6);
                backdrop-filter: blur(30px);
                border-radius: 50px;
                height: 100%;
                display: flex;
                padding: 32px 87px 32px 87px;
                gap: 35px;
                margin: 0 auto 0 auto;
                position: relative;
                z-index: 99;
                @media (max-width: 1520px) {
                    padding: 25px 50px 25px 10px;
                }
                @media (max-width: 1520px) {
                    gap: 15px;
                }
                @media (max-width: 1400px) {
                    width: 100%;
                }
                @media (max-width: 1100px) {
                    display: grid;
                    grid-template: repeat(2,1fr)/repeat(2,1fr);
                }
                @media (max-width: 700px) {
                    padding: 32px 50px;
                }
                @media (max-width: 480px) {
                    padding: 20px 40px 30px 40px;
                    gap: 5%;
                    grid-template: repeat(2,0fr)/repeat(2,1fr);
                }
                &-item {
                    border-right: 2px solid #AB9CA3;
                    padding-right: 20px;
                    width: 229px;
                    @media (max-width: 1400px) {
                        width: 25%;
                    }
                    @media (max-width: 1100px) {
                        width: 229px;
                    }
                    @media (max-width: 700px) {
                        width: 110px;
                    }
                    &-name {
                        font-size: 20px;
                        line-height: 25px;
                        letter-spacing: 0.08em;
                        margin-top: 10px;
                        @media (max-width: 1520px) {
                            font-size: 18px;
                            line-height: normal;
                        }
                        @media (max-width: 700px) {
                            font-size: 20px;
                            line-height: 25px;
                            span {
                                display: none;
                            }
                        }
                    }
                    &-cost {
                        color: #FF26C2;
                        font-size: 16px;
                        line-height: 20px;
                        letter-spacing: 0.08em;
                        margin-top: 5px;
                        margin-bottom: 10px;
                    }
                    &-change {
                        color: #58FFF5;
                        font-size: 16px;
                        line-height: 20px;
                        letter-spacing: 0.08em;
                        margin-top: 5px;
                        margin-bottom: 18px;
                    }
                }
            }
            &:hover {
                .img-btc {
                    top: 120px;
                    scale: 1.2;
                    right: -120px;
                    @media (max-width: 1100px) {
                        top: 90%;
                        right: -40px;
                    }
                    @media (max-width: 480px) {
                        right: 0;
                    }
                }
                .img-eth {
                    bottom: -30px;
                    left: -200px;
                    @media (max-width: 1100px) {
                        left: -50px;
                        top: -70px;
                        scale: 1.1;
                    }
                }
            }
            .img-btc {
                position: absolute;
                top: 90px;
                right: -90px;
                transition: all .5s;
                @media (max-width: 1520px) {
                    width: 200px;
                }
                @media (max-width: 1100px) {
                    width: 125px;
                    top: 80%;
                    right: -50px;
                }
                @media (max-width: 480px) {
                    right: 0;
                }
            }
            .img-eth {
                position: absolute;
                left: -140px;
                bottom: 40px;
                transition: all .5s;
                @media (max-width: 1520px) {
                    width: 220px;
                }
                @media (max-width: 1100px) {
                    width: 110px;
                    top: -50px;
                    left: -50px;
                }
            }
        }
    }
}
</style>
