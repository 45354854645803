import Vue from 'vue'
import store from '@/store'
import router from '@/router'
import i18n from '@/plugins/i18n'
import App from "./App.vue";
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

new Vue({
  i18n,
  store,
  router,
  ...App
})
