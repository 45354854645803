<template>
  <div class="container-padding benefit__wrap">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="benefits-items__wrap">
          <h3 class="benefits__title" v-html="$t('benefit_title')"></h3>
          <div class="benefits-right__text d-block d-md-none">
            <div class="benefits-right__width">
              <p class="mb-0">The prior is to guarantee to each user the accuracy, applicability, reliability, integrity and high-quality performance of trading experience. This trading platform provides a broker, which gives you fiduciary relationships and obligations.</p>
              <div class="d-flex justify-content-center">
                <a :href="'https://user.ipomarkets.org/signup?lang=' + $i18n.locale" class="btn-default">Start trading</a>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 col-lg-6 col-lg-4">
              <div class="benefits__item">
                <div class="benefits-item__text">
                  {{ $t('benefit_item_1') }}
                </div>
              </div>
            </div>
            <div class="col-6 col-lg-6 col-lg-4">
              <div class="benefits__item">
                <div class="benefits-item__text">
                  {{ $t('benefit_item_4') }}
                </div>
              </div>
            </div>
            <div class="col-6 col-lg-6 col-lg-4">
              <div class="benefits__item">
                <div class="benefits-item__text">
                  {{ $t('benefit_item_5') }}
                </div>
              </div>
            </div>
            <div class="col-6 col-lg-6 col-lg-4">
              <div class="benefits__item">
                <div class="benefits-item__text">
                  {{ $t('benefit_item_3') }}
                </div>
              </div>
            </div>
            <div class="col-6 col-lg-6 col-lg-4">
              <div class="benefits__item">
                <div class="benefits-item__text">
                  {{ $t('benefit_item_2') }}
                </div>
              </div>
            </div>
            <div class="col-6 col-lg-6 col-lg-4">
              <div class="benefits__item">
                <div class="benefits-item__text">
                  {{ $t('benefit_item_6') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 d-none d-md-block">
        <div class="benefits-right__text">
          <div class="benefits-right__width">
            <p class="mb-0">{{ $t('benefit_bottom_text') }}</p>
            <div class="d-flex justify-content-center">
              <a :href="'https://user.ipomarkets.org/signup?lang=' + $i18n.locale" class="btn-default">{{ $t('start_trading') }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Benefit"
}
</script>

<style lang="scss" scoped>
.benefit__wrap {
  padding-top: 103px;
}
.benefits__title {
  font-size: 56px;
  color: #fff;
  margin-bottom: 50px;
}
.col-6.col-lg-6 {
  margin-bottom: 30px;
}

.benefits__item {
  background-color: rgba(21, 21, 29, 0.6);
  padding: 21px 10px;
  height: 100%;
  border-left: 1px solid #FF83B7;
}

.col-12.col-lg-6 {
  min-height: 236px;
  &:first-child, &:nth-child(2), &:nth-child(3) {
    margin-bottom: 50px;
  }
}
.benefits-item__text {
  font-family: EncodeSansLight, sans-serif;
  color: #fff;
  font-size: 20px;
  font-weight: 300;
}
.benefits-right__text {
  font-size: 25px;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  text-align: center;
  margin-top: 180px;
  padding-right: 22px;
  padding-top: 20px;
}

.benefits-right__width {
  max-width: 683px;
}
</style>
