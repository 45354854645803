<template>
  <footer class="container-padding footer position-relative">
    <div class="footer__wrap">
      <div class="d-flex flex-wrap">
        <div class="col-6 col-md-12 footer-menu__list">
          <!-- <div class="footer-menu__item">
            <a href="#" class="footer-menu__link">{{ $t('cookies_policy') }}</a>
          </div>
          <div class="footer-menu__item">
            <a href="#" class="footer-menu__link">{{ $t('footer_legal_doc') }}</a>
          </div> -->
          <div class="footer-menu__item">
            <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank" class="footer-menu__link">{{ $t('terms_conditions') }}</a>
          </div>
          <div class="footer-menu__item">
            <a href="/docs/AML_POLICY.pdf" target="_blank" class="footer-menu__link">{{ $t('footer_aml_policy') }}</a>
          </div>
          <div class="footer-menu__item">
            <a href="/docs/PRIVACY_POLICY.pdf" target="_blank" class="footer-menu__link">{{ $t('privacy_policy') }}</a>
          </div>
          <!-- <div class="footer-menu__item">
            <a href="#" class="footer-menu__link">{{ $t('open_demo_account') }}</a>
          </div> -->
          <div class="footer-menu__item">
            <a :href="'https://user.ipomarkets.org/signup?lang=' + $i18n.locale" class="footer-menu__link">{{ $t('open_account') }}</a>
          </div>
          <!-- <div class="footer-menu__item">
            <a href="#" class="footer-menu__link">{{ $t('affiliate_program') }}</a>
          </div> -->
          <div class="footer-menu__item">
            <a href="mailto:support@ipo-markets.info" class="footer-menu__link">support@ipo-markets.info</a>
          </div>
          <div class="footer-menu__item">
            <a href="tel:+442030973959" class="footer-menu__link">+442030973959</a>
          </div>
        </div>
        <div class="d-none d-md-flex col-6 col-md-12 footer-download__wrap">
          <div class="footer-download__item">
            <a href="#" class="footer-download__link"><img src="@/assets/img/app-store-download.png" alt="" class="img-fluid"></a>
          </div>
          <div class="footer-download__item">
            <a href="#" class="footer-download__link"><img src="@/assets/img/google-play-download.png" alt="" class="img-fluid"></a>
          </div>
          <div class="footer-download__item">
            <a href="#" class="footer-download__link"><img src="@/assets/img/macos-download.png" alt="" class="img-fluid"></a>
          </div>
          <div class="footer-download__item">
            <a href="#" class="footer-download__link"><img src="@/assets/img/windows-download.png" alt="" class="img-fluid"></a>
          </div>
          <div class="footer-download__item">
            <a href="#" class="footer-download__link"><img src="@/assets/img/visa-icon.png" alt="" class="img-fluid"></a>
          </div>
          <div class="footer-download__item">
            <a href="#" class="footer-download__link"><img src="@/assets/img/mastrcard-icon.png" alt="" class="img-fluid"></a>
          </div>
          <div class="footer-download__item">
            <a href="#" class="footer-download__link"><img src="@/assets/img/bank-icon.png" alt="" class="img-fluid"></a>
          </div>
          <div class="footer-download__item">
            <a href="#" class="footer-download__link"><img src="@/assets/img/bitcoin-icon.png" alt="" class="img-fluid"></a>
          </div>
        </div>
        <div class="d-flex d-md-none col-6 col-md-12 footer-download__wrap">
          <div class="d-flex flex-wrap">
            <div class="col-6 col-md-3 d-flex flex-column">
              <div class="footer-download__item">
                <a href="#" class="footer-download__link"><img src="@/assets/img/app-store-download.png" alt="" class="img-fluid"></a>
              </div>
              <div class="footer-download__item">
                <a href="#" class="footer-download__link"><img src="@/assets/img/google-play-download.png" alt="" class="img-fluid"></a>
              </div>
              <div class="footer-download__item">
                <a href="#" class="footer-download__link"><img src="@/assets/img/macos-download.png" alt="" class="img-fluid"></a>
              </div>
              <div class="footer-download__item">
                <a href="#" class="footer-download__link"><img src="@/assets/img/windows-download.png" alt="" class="img-fluid"></a>
              </div>
            </div>
            <div class="col-6 col-md-3 d-flex flex-column">
              <div class="footer-download__item">
                <a href="#" class="footer-download__link"><img src="@/assets/img/visa-icon.png" alt="" class="img-fluid"></a>
              </div>
              <div class="footer-download__item">
                <a href="#" class="footer-download__link"><img src="@/assets/img/mastrcard-icon.png" alt="" class="img-fluid"></a>
              </div>
              <div class="footer-download__item">
                <a href="#" class="footer-download__link"><img src="@/assets/img/bank-icon.png" alt="" class="img-fluid"></a>
              </div>
              <div class="footer-download__item">
                <a href="#" class="footer-download__link"><img src="@/assets/img/bitcoin-icon.png" alt="" class="img-fluid"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-risk__wrap">
        <p class="footer-risk__text mb-0">{{ $t('risk_warning') }} <br>
          {{ $t('risk_warning_desc') }}
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style lang="scss" scoped>
.footer {
  z-index: 9;
}
.earth-fixed__img {
  position: absolute;
  bottom: 0;
  right: 0;
  // background-image: url("@/assets/img/earth-footer.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: bottom right;
}
.footer__wrap {
  border-top: 1px solid #fff;
}
.footer-menu__list {
  display: flex;
  padding: 25px 0;
}
.footer-menu__item {
  &:not(:first-child) {
    .footer-menu__link {
      margin-left: 40px;
    }
  }
  .footer-menu__link {
    font-size: 16px;
    color: #fff;
    text-decoration: navajowhite;
  }
}
.footer-download__wrap {
  display: flex;
  align-items: center;
}
.footer-download__item {
  &:not(:first-child) {
    .footer-download__link {
      margin-left: 40px;
    }
  }
  .footer-download__link {
    img {
      width: 103px;
      height: 30px;
      object-fit: contain;
    }
  }
}
.footer-risk__wrap {
  padding-top: 22px;
  padding-bottom: 50px;
  .footer-risk__text {
    font-size: 16px;
    color: #fff;
  }
}
</style>
