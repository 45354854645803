<template>
    <div class="straight">
        <div class="main-wr straight-container">
            <div class="side">
                <div class="straight-container-header" v-html="$t('tools.whatToTrade')"></div>
                <div class="straight-container-text t-light">
                    <div>
                        {{ $t('tools.whatToTradeText1') }}
                    </div>
                    <div v-html="$t('tools.whatToTradeText2')"></div>
                </div>
            </div>
            <div class="side">
                <img src="@/assets/images/Tools/what_to_trade.png" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhatToTrade',
}
</script>

<style lang="scss" scoped>
.straight {
    margin-top: 110px;
    @media (max-width: 480px) {
        margin-top: 50px;
    }
    &-container {
        display: flex;
        justify-content: space-between;
        @media (max-width: 1024px) {
            display: block;
        }
        @media (max-width: 480px) {
        }
        &-header {
            font-size: 50px;
            line-height: 62px;
            letter-spacing: 0.08em;
            width: 70vw;
            @media (max-width: 1600px) {
                font-size: 40px;
                line-height: 50px;
            }
            @media (max-width: 1024px) {
                width: 100%;
            }
            @media (max-width: 480px) {
                font-size: 18px;
                line-height: 22px;
                letter-spacing: 0.08em;
            }
            span {
                background: linear-gradient(90.02deg, #09EBFB -0.5%, #9F60C6 121.25%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                font-family:'EncodeSans';
                font-weight: 800;
            }
        }
        &-text {
            margin-top: 50px;
            font-size: 25px;
            line-height: 31px;
            @media (max-width: 1600px) {
                font-size: 18px;
                line-height: 22px;
            }
            @media (max-width: 1024px) {
                margin-top: 30px;
            }
            @media (max-width: 480px) {
                font-size: 13px;
                line-height: 16px;
            }
            .par {
                margin-top: 30px;
                @media (max-width: 1024px) {
                    margin-top: 10px;
                }
                @media (max-width: 480px) {
                    margin-top: 15px;
                }
            }
        }
        .side {
            width: 50%;
            @media (max-width: 1024px) {
                width: 100%;
            }
        }
        img {
            &:hover {
                scale: 1.02;
            }
            transition: all .5s;
            margin-top: 70px;
            margin-left: auto;
            display: flex;
            margin-right: -70px;
            @media (max-width: 1600px) {
                height: 450px;
                margin-top: 70px;
                margin-right: -50px;
            }
            @media (max-width: 1400px) {
                margin-right: -70px;
                margin-top: 100px;
                height: 420px;
            }
            @media (max-width: 1024px) {
                margin: 30px auto 0 auto;
            }
            @media (max-width: 480px) {
                height: 309px;
                margin-top: 50px;
                width: 100%;
                height: auto;
            }
        }
    }
}
</style>
