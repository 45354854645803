<template>
  <div class="our-platforms">
    <div class="main-wr our-platforms-container">
      <div class="our-platforms-container-header t-bold" v-html="$t('tools.our_platform_title')"></div>
      <div class="our-platforms-container-text ta-left t-light">
        {{ $t('tools.our_platform_text') }}
      </div>
      <div class="our-platforms-container-download">
        <img class="google-play" alt="" />
        <img class="app-store" alt="" />
        <img class="windows" alt="" />
        <img class="mac" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OurPlatform',
}
</script>

<style lang="scss" scoped>
.our-platforms {
  margin-top: 110px;
  @media (max-width: 480px) {
    margin-top: 50px;
  }
  &-container {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1200px) {
      display: block;
    }
    &-header {
      color: #726FC2;
      font-weight: 900;
      font-size: 70px;
      line-height: 80px;
      @media (max-width: 1600px) {
        font-size: 57px;
        line-height: 71px;
      }
      @media (max-width: 1300px) {
        font-size: 45px;
        line-height: normal;
      }
      @media (max-width: 1200px) {
        font-size: 70px;
        line-height: 88px;
      }
      @media (max-width: 480px) {
        font-size: 57px;
        line-height: 71px;
      }
    }
    &-text {
      font-weight: 400;
      font-size: 20px;
      line-height: 25px;
      width: 549px;
      @media (max-width: 1600px) {
        font-size: 16px;
        line-height: 20px;
        width: 390px;
      }
      @media (max-width: 1300px) {
        font-size: 14px;
        line-height: normal;
      }
      @media (max-width: 1200px) {
        font-size: 20px;
        line-height: 25px;
        margin-top: 30px;
        width: 100%;
      }
      @media (max-width: 480px) {
        font-size: 13px;
        line-height: 16px;
      }
    }
    &-download {
      display: grid;
      grid-template: repeat(2,1fr)/repeat(2,1fr);
      gap: 0 70px;
      @media (max-width: 1450px) {
        gap: 0 20px;
      }
      @media (max-width: 1200px) {
        gap: 20px 30px;
        margin: 40px auto 0 auto;
        width: 500px;
      }
      @media (max-width: 700px) {
        grid-template: repeat(2,0fr)/repeat(2,0fr);
        width: 355px;
      }
      @media (max-width: 480px) {
        margin-top: 30px;
        width: 290px;
      }
      img {
        cursor: pointer;
        @media (max-width: 1380px) {
          width: 150px;
        }
        @media (max-width: 1200px) {
          width: 207px;
        }
        @media (max-width: 700px) {
          width: 162px;
        }
        @media (max-width: 480px) {
          width: 130px;
        }
      }
      .google-play {
        content: url('@/assets/images/Tools/dwd_google_play.png');
        &:hover {
          content: url('@/assets/images/Tools/dwd_google_play_hover.png');
        }
      }
      .app-store {
        content: url('@/assets/images/Tools/dwd_app_store.png');
        &:hover {
          content: url('@/assets/images/Tools/dwd_app_store_hover.png');
        }
      }
      .windows {
        content: url('@/assets/images/Tools/dwd_windows.png');
        &:hover {
          content: url('@/assets/images/Tools/dwd_windows_hover.png');
        }
      }
      .mac {
        content: url('@/assets/images/Tools/dwd_mac.png');
        &:hover {
          content: url('@/assets/images/Tools/dwd_mac_hover.png');
        }
      }
    }
  }
}
</style>
