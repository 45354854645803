<template>
  <div class="hit">
    <div class="container">
      <div class="hit__body">
        <div class="hit__title" v-html="$t('affiliates.hit_title')"></div>
        <div class="hit__subtitle">
          {{ $t('affiliates.hit_text') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hit",
  layout: "basic",
  components: {},
};
</script>

<style scoped lang="scss">
.hit {
  background: url("@/assets/images/Affiliates/4/1.png") center 0 / cover
    no-repeat;
  &__body {
    padding: 71px 0;
    text-align: center;
    margin-bottom: 50px;
    @media (max-width: $md3+px) {
      padding: 50px 0;
    }
    @media (max-width: $md3+px) {
      padding: 45px 0;
    }
    @media (max-width: $md4+px) {
      padding: 40px 0;
    }
    @media (max-width: $md5+px) {
      padding: 35px 0;
    }
    @media (max-width: $md6+px) {
      padding: 29px 0;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 50px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.08em;
    span {
      display: block;
    }
    @media (max-width: $md1+px) {
      font-size: 40px;
    }
    @media (max-width: $md2+px) {
      font-size: 35px;
    }
    @media (max-width: $md3+px) {
      font-size: 30px;
      margin-bottom: 25px;
    }
    @media (max-width: $md4+px) {
      font-size: 25px;
      margin-bottom: 20px;
    }
    @media (max-width: $md5+px) {
      font-size: 20px;
      margin-bottom: 15px;
    }
    @media (max-width: $md6+px) {
      font-size: 18px;
      margin-bottom: 10px;
    }
    background: linear-gradient(90.17deg, #58fff5 12.14%, #ff26c2 99.91%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    margin: 0 auto 30px auto;
  }

  &__subtitle {
    font-size: 30px;
    line-height: 1.3;
    text-align: center;

    color: #ffffff;
    max-width: 750px;
    margin: 0 auto;
    @media (max-width: $md2+px) {
      font-size: 29px;
    }
    @media (max-width: $md3+px) {
      font-size: 25px;
    }
    @media (max-width: $md4+px) {
      font-size: 21px;
    }
    @media (max-width: $md5+px) {
      font-size: 17px;
    }
    @media (max-width: $md6+px) {
      font-size: 13px;
      max-width: 340px;
    }
  }
}
.container {
}
</style>
