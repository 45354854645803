<template>
  <div class="three">
    <div class="container">
      <div class="three__body">
        <div class="three__title" v-html="$t('affiliates.levels_title')"></div>
        <div class="three-row">
          <div class="three-row__column">
            <div class="three-row-item">
              <div class="three-row-item__img">
                <img src="@/assets/images/Affiliates/2/1.png" alt="" />
              </div>
              <div class="three-row-item__title">{{ $t('affiliates.levels_item_title_1') }}</div>
              <div class="three-row-item__subtitle">
                {{ $t('affiliates.levels_item_text_1') }}
              </div>
            </div>
          </div>
          <div class="three-row__column">
            <div class="three-row-item">
              <div class="three-row-item__img">
                <img src="@/assets/images/Affiliates/2/2.png" alt="" />
              </div>
              <div class="three-row-item__title">{{ $t('affiliates.levels_item_title_2') }}</div>
              <div class="three-row-item__subtitle">
                {{ $t('affiliates.levels_item_text_2') }}
              </div>
            </div>
          </div>
          <div class="three-row__column">
            <div class="three-row-item">
              <div class="three-row-item__img">
                <img src="@/assets/images/Affiliates/2/3.png" alt="" />
              </div>
              <div class="three-row-item__title">{{ $t('affiliates.levels_item_title_3') }}</div>
              <div class="three-row-item__subtitle">
                {{ $t('affiliates.levels_item_text_3') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Three",
  layout: "basic",
  components: {},
};
</script>

<style lang="scss">
.three {
  &__body {
    margin-bottom: 110px;
    @media (max-width: $md4+px) {
      margin: 0 auto 90px auto;
    }
    @media (max-width: $md5+px) {
      margin: 0 auto 70px auto;
    }
    @media (max-width: $md6+px) {
      margin: 0 auto 50px auto;
    }
  }

  &__title {
    margin-bottom: 50px;
    font-weight: 400;
    font-size: 50px;
    line-height: 1.2;
    letter-spacing: 0.08em;
    color: #ffffff;
    text-align: center;
    @media (max-width: $md1+px) {
      font-size: 40px;
      margin-bottom: 45px;
    }
    @media (max-width: $md2+px) {
      font-size: 35px;
      margin-bottom: 40px;
    }
    @media (max-width: $md3+px) {
      font-size: 30px;
      margin-bottom: 35px;
    }
    @media (max-width: $md4+px) {
      font-size: 25px;
      margin-bottom: 30px;
    }
    @media (max-width: $md5+px) {
      font-size: 20px;
      margin-bottom: 25px;
    }
    @media (max-width: $md6+px) {
      font-size: 18px;
      margin-bottom: 20px;
    }
    span {
      background-image: linear-gradient(90deg, #58fff5, #82c9e8, #b587d9);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
.three-row {
  display: flex;
  justify-content: space-between;
  max-width: 1220px;
  margin: 0 auto;
  @media (max-width: $md4+px) {
    flex-wrap: wrap;
  }
  &__column {
    flex: 0 1 330px;
    @media (max-width: $md3+px) {
      flex: 0 1 310px;
    }
    @media (max-width: $md4+px) {
      flex: 0 1 100%;
      margin-bottom: 45px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    text-align: center;
    &:nth-child(1) {
      .three-row-item__img img {
        width: 80px;
      }
    }
    &:nth-child(2) {
      .three-row-item__img img {
        width: 77px;
      }
    }
    &:nth-child(3) {
      .three-row-item__img img {
        width: 62px;
      }
    }
  }
}
.three-row-item {
  @media (max-width: $md4+px) {
    max-width: 300px;
    margin: 0 auto;
  }
  &__img {
    height: 80px;
    margin-bottom: 20px;
  }

  &__title {
    font-size: 21px;
    line-height: 1.2;
    color: #ffffff;
    margin-bottom: 25px;
  }

  &__subtitle {
    font-size: 16px;
    @media (max-width: $md3+px) {
      font-size: 15px;
    }
    @media (max-width: $md4+px) {
      font-size: 13px;
    }
    color: #ffffff;
    line-height: 1.2;
    font-family: "EncodeSansLight";
  }
}
</style>
