<template>
  <div class="position-relative overflow-hidden">
    <transition name="bounce">
      <Child/>
    </transition>
    <div class="earth-fixed__img"></div>
  </div>
</template>

<script>
import Child from "@/components/Child";
export default {
  components: {
    Child,
  },
}
</script>

<style lang="scss" scoped>
.earth-fixed__img {
  position: absolute;
  bottom: 0;
  right: 0;
  background-image: url("@/assets/img/earth-footer.png");
  width: calc(536px * 0.8);
  height: calc(530px * 0.8);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: cover;
}
</style>
