<template>
  <div class="container-padding form-request__container">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-request__wrap">
          <h4 class="form-request__title">{{ $t("about_us_title") }}</h4>
          <p class="mb-0 form-request__text" v-html="$t('about_us_text')"></p>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-request__wrap">
          <h4 class="form-request__title">{{ $t('tools.trade_text_1') }}</h4>
          <form action="#" method="post">
            <div class="form__group">
              <input type="text" :placeholder="$t('form_full_name')" class="form__input">
            </div>
            <div class="form__group">
              <input type="text" :placeholder="$t('form_phone')" class="form__input">
            </div>
            <!-- <div class="form__group">
              <input type="email" :placeholder="$t('form_phone')" class="form__input">
            </div> -->
            <button class="btn-default" type="submit">{{ $t('form_send') }}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormRequest"
}
</script>

<style lang="scss" scoped>
.form-request__container {
  padding-top: 115px;
  padding-bottom: 115px;
}
.form-request__wrap {
  max-width: 590px;
}
.form-request__title {
  font-size: 40px;
  color: #58FFF5;
  margin-bottom: 35px;
}
.form-request__text {
  font-size: 20px;
  color: #fff;
}
.form__group {
  max-width: 580px;
  margin-bottom: 15px;
  .form__input {
    width: 100%;
    padding: 15px 15px;
    background-color: #15151D;
    border: none;
    color: #fff;
    &::placeholder {
      font-size: 14px;
      color: #fff;
    }
  }
}
.btn-default {
  background-color: transparent;
  border: none;
  margin-top: 30px;
}
</style>
