<template>
  <div class="commission">
    <div class="container">
      <div class="commission__body">
        <div class="commission__title">{{ $t('affiliates.popular_title_1') }}rs</div>
        <div class="commission-wrappper">
          <div class="commission-row">
            <div class="commission-row__name">{{ $t('affiliates.ties') }} 1</div>
            <div class="commission-row__price">{{ $t('affiliates.per_label') }}</div>
          </div>
          <div class="commission-row">
            <div class="commission-row__name">{{ $t('affiliates.ties') }} 1</div>
            <div class="commission-row__price">{{ $t('affiliates.per_label') }}</div>
          </div>
          <div class="commission-row">
            <div class="commission-row__name">{{ $t('affiliates.ties') }} 1</div>
            <div class="commission-row__price">{{ $t('affiliates.per_label') }}</div>
          </div>
          <div class="commission-row">
            <div class="commission-row__name">{{ $t('affiliates.ties') }} 1</div>
            <div class="commission-row__price">{{ $t('affiliates.per_label') }}</div>
          </div>
          <div class="commission-row">
            <div class="commission-row__name">{{ $t('affiliates.ties') }} 1</div>
            <div class="commission-row__price">{{ $t('affiliates.per_label') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Commission",
  layout: "basic",
  components: {},
};
</script>

<style scoped lang="scss">
.commission {
  &__body {
    margin-bottom: 100px;
    @media (max-width: $md4+px) {
      margin: 0 auto 90px auto;
    }
    @media (max-width: $md5+px) {
      margin: 0 auto 70px auto;
    }
    @media (max-width: $md6+px) {
      margin: 0 auto 50px auto;
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 50px;
    font-weight: 400;
    font-size: 50px;
    line-height: 1.2;
    letter-spacing: 0.08em;
    color: #ffffff;
    @media (max-width: $md1+px) {
      font-size: 40px;
      margin-bottom: 45px;
    }
    @media (max-width: $md2+px) {
      font-size: 35px;
      margin-bottom: 40px;
    }
    @media (max-width: $md3+px) {
      font-size: 30px;
      margin-bottom: 35px;
    }
    @media (max-width: $md4+px) {
      font-size: 25px;
      margin-bottom: 30px;
    }
    @media (max-width: $md5+px) {
      font-size: 20px;
      margin-bottom: 25px;
    }
    @media (max-width: $md6+px) {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
}
.commission-wrappper {
  max-width: 780px;
  margin: 0 auto;
}
.commission-row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #272727;
  padding: 0 13px 13px 13px;
  max-width: 580px;

  margin: 0 auto 22px auto;
  @media (max-width: $md5+px) {
    max-width: 300px;
  }
  &__name {
    font-size: 16px;
    color: #ffffff;
    @media (max-width: $md5+px) {
      font-size: 10px;
    }
  }

  &__price {
    font-weight: 500;
    font-size: 16px;
    color: #6a6e87;
    @media (max-width: $md5+px) {
      font-size: 10px;
    }
  }
}
</style>
