<template>
  <div class="media">
    <div class="container">
      <a :href="'https://user.ipomarkets.org/signup?lang=' + $i18n.locale" class="media__btn">{{ $t('affiliates.sign_up_or_login') }}</a>
      <div class="media__body">
        <div class="media__title">{{ $t('affiliates.pack_title') }}</div>
        <div class="media__subtitle">
          {{ $t('affiliates.pack_text_1') }}
        </div>
        <div class="media__subtitle">
          {{ $t('affiliates.pack_text_2') }}
        </div>
        <div class="media-row">
          <div class="media-column">
            <div class="media-column__img">
              <img src="@/assets/images/Affiliates/5/1.svg" alt="" />
            </div>
            <div class="media-column__text" v-html="$t('affiliates.logo_files')"></div>
          </div>
          <div class="media-column">
            <div class="media-column__img">
              <img src="@/assets/images/Affiliates/5/2.svg" alt="" />
            </div>
            <div class="media-column__text" v-html="$t('affiliates.static_banners')"></div>
          </div>
          <div class="media-column">
            <div class="media-column__img">
              <img src="@/assets/images/Affiliates/5/3.svg" alt="" />
            </div>
            <div class="media-column__text" v-html="$t('affiliates.animated_banners')"></div>
          </div>
          <div class="media-column">
            <div class="media-column__img">
              <img src="@/assets/images/Affiliates/5/4.svg" alt="" />
            </div>
            <div class="media-column__text" v-html="$t('affiliates.email_templates')"></div>
          </div>
          <div class="media-column">
            <div class="media-column__img">
              <img src="@/assets/images/Affiliates/5/5.svg" alt="" />
            </div>
            <div class="media-column__text" v-html="$t('affiliates.information_sheet')"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Media",
  layout: "basic",
  components: {},
};
</script>

<style scoped lang="scss">
.media {
  &__btn {
    font-weight: 800;
    font-size: 18px;
    line-height: 59px;
    letter-spacing: 0.08em;
    color: #af50c0;
    width: 523px;
    height: 59px;
    text-align: center;
    margin: 0 auto 110px auto;
    background: url("@/assets/images/Affiliates/5/btn.png") 0 0 / cover
      no-repeat;
    cursor: pointer;
    transition: all 0.3s linear;
    display: block;
    text-decoration: none;
    &:hover {
      color: #66edf1;
    }
    @media (max-width: $md4+px) {
      width: 392px;
      height: 44px;
      line-height: 44px;
      font-size: 13px;
      margin: 0 auto 90px auto;
    }
    @media (max-width: $md5+px) {
      width: 320px;
      background: url("@/assets/images/Affiliates/5/btn.png") 0 0 / contain
        no-repeat;
      height: 38px;
      line-height: 38px;
      font-size: 13px;
      margin: 0 auto 70px auto;
    }
    @media (max-width: $md6+px) {
      margin: 0 auto 50px auto;
    }
    @media (max-width: 370px) {
      width: 280px;
      font-size: 12px;
      height: 33px;
      line-height: 33px;
    }
  }
  &__body {
    margin-bottom: 120px;
    @media (max-width: $md4+px) {
      margin: 0 auto 90px auto;
    }
    @media (max-width: $md5+px) {
      margin: 0 auto 70px auto;
    }
    @media (max-width: $md6+px) {
      margin: 0 auto 50px auto;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 50px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.08em;
    margin-bottom: 25px;
    background: linear-gradient(90deg, #58fff5 3.61%, #fc36ed 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    @media (max-width: $md1+px) {
      font-size: 40px;
    }
    @media (max-width: $md2+px) {
      font-size: 35px;
    }
    @media (max-width: $md3+px) {
      font-size: 30px;
    }
    @media (max-width: $md4+px) {
      font-size: 25px;
    }
    @media (max-width: $md5+px) {
      font-size: 20px;
      margin-bottom: 15px;
    }
    @media (max-width: $md6+px) {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }

  &__subtitle {
    font-family: "EncodeSansLight";
    font-size: 25px;
    line-height: 1.3;
    text-align: center;
    color: #ffffff;
    max-width: 770px;
    margin: 0 auto 35px auto;
    @media (max-width: $md1+px) {
      font-size: 21px;
      max-width: 550px;
    }
    @media (max-width: $md2+px) {
      font-size: 19px;
      margin-bottom: 30px;
    }
    @media (max-width: $md3+px) {
      font-size: 17px;
      margin-bottom: 20px;
    }
    @media (max-width: $md4+px) {
      font-size: 13px;
      margin-bottom: 10px;
      max-width: 380px;
    }
  }
}

.media-row {
  display: flex;
  justify-content: space-between;
  max-width: 1073px;
  margin: 40px auto 0 auto;
  @media (max-width: $md5+px) {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
.media-column {
  text-align: center;
  @media (max-width: $md5+px) {
    flex: 0 1 33.333%;
    margin-bottom: 20px;
  }
  @media (max-width: $md6+px) {
    margin-bottom: 10px;
  }
  &__img {
    height: 88.5px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: $md4+px) {
      margin-bottom: 20px;
    }
    @media (max-width: $md6+px) {
      height: 48px;
      margin-bottom: 10px;
    }
  }
  &:nth-child(1) {
    .media-column__img img {
      width: 66px;
      height: 82px;
      @media (max-width: $md6+px) {
        width: 36px;
        height: 44.72px;
      }
    }
  }
  &:nth-child(2) {
    .media-column__img img {
      width: 76.5px;
      height: 80px;
      @media (max-width: $md6+px) {
        width: 41.72px;
        height: 43.63px;
      }
    }
  }
  &:nth-child(3) {
    .media-column__img img {
      width: 76.5px;
      height: 80px;
      @media (max-width: $md6+px) {
        width: 41.72px;
        height: 43.63px;
      }
    }
  }
  &:nth-child(4) {
    @media (max-width: $md6+px) {
      margin-left: 40px;
    }
    .media-column__img img {
      width: 90.5px;
      height: 53.5px;
      @media (max-width: $md6+px) {
        width: 49.36px;
        height: 29.18px;
      }
    }
  }
  &:nth-child(5) {
    @media (max-width: $md6+px) {
      margin-right: 40px;
    }
    .media-column__img img {
      width: 60.5px;
      height: 88.5px;
      @media (max-width: $md6+px) {
        width: 33px;
        height: 48.27px;
      }
    }
  }
  &__text::v-deep {
    font-size: 25px;
    line-height: 1.3;
    text-align: center;
    @media (max-width: $md3+px) {
      font-size: 22px;
    }
    @media (max-width: $md4+px) {
      font-size: 20px;
    }
    @media (max-width: $md5+px) {
      font-size: 17px;
    }
    @media (max-width: $md6+px) {
      font-size: 13px;
    }
    color: #ffffff;
    span {
      display: block;
    }
  }
}
</style>
