<template>
  <div class="map__container d-none d-md-flex">
    <img src="@/assets/img/map.png" alt="" class="img-fluid map__img">
  </div>
</template>

<script>
export default {
  name: "Map"
}
</script>

<style lang="scss" scoped>
.map__container {
  padding-top: 100px;
  justify-content: center;
}
.map__img {
  margin: auto;
}
</style>
