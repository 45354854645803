<template>
    <div>
        <div class="container-padding">
            <Menu />
        </div>
        <div class="graphic">
            <div class="container">
                <div class="graphic__body">
                    <div class="graphic__title" v-html="$t('affiliates.banner_title')"></div>
                    <div class="graphic-row">
                        <div class="graphic-row__left">
                            {{ $t('affiliates.banner_text') }}
                        </div>
                        <div class="graphic-row__right">
                            <img src="@/assets/images/Affiliates/1/1.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Menu from '../Menu.vue';
export default {
    name: 'Graphic',
    layout: 'basic',
    components: {
        Menu
    }
}
</script>

<style scoped  lang="scss">
.graphic {
    margin: 150px 0 110px 0;

    @media(max-width: $md1+px) {
        margin: 130px 0 100px 0;
    }

    @media(max-width: $md2+px) {
        margin: 120px 0 80px 0;
    }

    @media(max-width: $md3+px) {
        margin: 110px 0 70px 0;
    }

    @media(max-width: $md4+px) {
        margin: 90px 0 60px 0;
    }

    @media(max-width: $md5+px) {
        margin: 70px 0 50px 0;
    }

    @media(max-width: $md6+px) {
        margin: 50px 0 40px 0;
    }

    &__body {}

    &__title {
        margin-bottom: 50px;
        font-weight: 400;
        font-size: 50px;
        line-height: 1.2;
        letter-spacing: 0.08em;
        color: #FFFFFF;

        @media(max-width: $md1+px) {
            font-size: 40px;
            margin-bottom: 45px;
        }

        @media(max-width: $md2+px) {
            font-size: 35px;
            margin-bottom: 40px;
        }

        @media(max-width: $md3+px) {
            font-size: 30px;
            margin-bottom: 35px;
        }

        @media(max-width: $md4+px) {
            font-size: 25px;
            margin-bottom: 30px;
        }

        @media(max-width: $md5+px) {
            font-size: 20px;
            margin-bottom: 25px;
        }

        @media(max-width: $md6+px) {
            font-size: 18px;
            margin-bottom: 20px;

        }

        span {
            background-image: linear-gradient(90deg, #58FFF5, #82C9E8, #B587D9);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

    }
}

.container {}

.graphic-row {
    display: flex;
    justify-content: space-between;

    @media(max-width: $md4+px) {
        flex-direction: column;
    }

    &__left {
        font-weight: 400;
        font-size: 25px;
        line-height: 1.3;
        color: #FFFFFF;
        font-family: "EncodeSansLight";
        flex: 0 1 519px;

        @media(max-width: $md2+px) {
            flex: 0 1 400px;

        }

        @media(max-width: $md3+px) {
            flex: 0 1 350px;
        }

        @media(max-width: $md2+px) {
            font-size: 21px;

        }

        @media(max-width: $md3+px) {
            font-size: 19px;

        }

        @media(max-width: $md4+px) {
            font-size: 17px;
            flex: 0 1 auto;
            margin-bottom: 35px;
        }

        @media(max-width: $md5+px) {
            font-size: 15px;

        }

        @media(max-width: $md6+px) {
            font-size: 13px;

        }
    }

    &__right {
        width: 624px;

        img {
            width: 100%;
            transition: scale 0.5s;

            &:hover {
                scale: 1.02
            }
        }

        @media(max-width: $md1+px) {
            width: 524px;

        }

        @media(max-width: $md3+px) {
            width: 450px;
        }

        @media(max-width: $md4+px) {
            width: 100%;
        }

    }
}
</style>
