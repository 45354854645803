import HomeView from '../views/HomeView'
import ToolsView from '../views/ToolsView';
import AffiliatesView from '../views/AffiliatesView';
import CompanySupportView from '../views/CompanySupportView.vue';
import AboutUsView from '../views/AboutUsView.vue';

export default [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/tools',
    name: 'tools',
    component: ToolsView,
  },
  {
    path: '/affiliates',
    name: 'affiliates',
    component: AffiliatesView,
  },
  {
    path: '/company-support',
    name: 'company-support',
    component: CompanySupportView,
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: AboutUsView,
  },
];
