<template>
    <div class="banner">
        <div class="container-padding">
            <Menu />
        </div>
        <img class="img-desc" src="@/assets/images/CompanySupport/banner.png" alt="" />
        <div class="main-wr banner-container">
            <div class="banner-container-header">
                {{ $t('company.company_title') }}
            </div>
            <div class="banner-container-text">
                <div>
                  {{ $t('company.company_text') }}
                </div>
                <!-- <div class="par">
                    {{ $t('company.company_text') }}
                </div> -->
                <div class="par">
                    {{ $t('company.company_white_text') }}
                </div>
            </div>
        </div>
        <img class="img-mob" src="@/assets/images/CompanySupport/banner.png" alt="" />
    </div>
</template>

<script>
import Menu from '../Menu.vue';
export default {
    name: 'Banner',
    components: { Menu },
}
</script>

<style lang="scss" scoped>
.banner {
    position: relative;
    overflow: hidden;
    .img-desc {
        position: absolute;
        z-index: 2;
        right: 0;
        bottom: 0;
        width: 800px;
        @media (max-width: 1800px) {
            // width: calc(100% - 200px - 655px);
        }
        @media (max-width: 1600px) {
            width: 600px;
        }
        @media (max-width: 1400px) {
            // top: 350px;
            width: 500px;
        }
        @media (max-width: 1200px) {
            display: none;
        }
        transition: .5s;
            &:hover {
                scale: 1.02;
            }
    }
    .img-mob {
            display: none;
            @media (max-width: 1200px) {
                display: flex;
                width: 80%;
                margin: 40px auto 0 auto;
            }
            @media (max-width: 480px) {
                margin-top: 10px;
                width: 100%;
            }
        }
    &-container {
        position: relative;
        z-index: 5;
        margin-top: 170px;
        overflow: hidden;
        @media (max-width: 480px) {
            margin-top: 80px;
        }
        &-header {
            color: #09ECFB;
            font-size: 50px;
            line-height: 62px;
            letter-spacing: 0.08em;
            @media (max-width: 1600px) {
                font-size: 40px;
                line-height: 50px;
            }
            @media (max-width: 480px) {
                font-size: 18px;
                line-height: 22px;
                letter-spacing: 0.08em;
            }
        }
        &-text {
            margin-top: 50px;
            font-size: 25px;
            line-height: 31px;
            width: 655px;
            @media (max-width: 1600px) {
                font-size: 18px;
                line-height: 22px;
            }
            @media (max-width: 1200px) {
                width: 100%;
            }
            @media (max-width: 1024px) {
                margin-top: 30px;
            }
            @media (max-width: 480px) {
                font-size: 13px;
                line-height: 16px;
            }
            .par {
                margin-top: 30px;
                @media (max-width: 1200px) {
                    margin-top: 15px;
                }
            }
        }
    }
}
</style>
