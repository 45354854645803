<template>
    <div class="advantages">
        <div class="advantages-container">
            <div class="advantages-container-header ta-center" v-html="$t('tools.the_advantages_title')"></div>
            <VueSlickCarousel
                v-bind="settings"
                @afterChange="afterPageChange"
                ref="carousel"
            >
                <div v-for="(slide, index) in slides" :key="slide" class="adv-slide"
                    :class="{ 'current-slide': index === sliderPageIndex, 'op': index !== sliderPageIndex }">
                    <div class="adv-slide-text">
                        <div class="adv-slide-text-t">
                            {{ $t(`tools.${slide}`) }}
                        </div>
                    </div>
                </div>
            </VueSlickCarousel>
            <div class="advantages-container-btn">
                <img @click="showPrev" class="advantages-container-btn-left" src="@/assets/images/Tools/arrow_left.png"
                    alt="" />
                <img @click="showNext" class="advantages-container-btn-right"
                    src="@/assets/images/Tools/arrow_right.png" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    name: 'Advantages',
    components: { VueSlickCarousel },
    data: () => ({
        slides: [
            'the_advantages_item_1',
            'the_advantages_item_2',
            'the_advantages_item_3',
            'the_advantages_item_4',
            'the_advantages_item_5',
            'the_advantages_item_6',
            'the_advantages_item_7',
            'the_advantages_item_8',
        ],
        sliderPageIndex: 0,
        settings: {
            "arrows": false,
            "slidesToShow": 5,
            "focusOnSelect": true,
            "centerMode": true,
            "centerPadding": '0px',
            "responsive": [
                {
                    "breakpoint": 1200,
                    "settings": {
                        "slidesToShow": 3,
                    }
                },
                {
                    "breakpoint": 800,
                    "settings": {
                        "slidesToShow": 1,
                        "centerPadding": '100px',
                    }
                },
                {
                    "breakpoint": 600,
                    "settings": {
                        "slidesToShow": 1,
                        "centerPadding": '50px',
                    }
                },
            ],
        },
    }),
    methods: {
        showPrev() {
            this.$refs.carousel.prev();
            console.log(this.sliderPageIndex)
        },
        showNext() {
            this.$refs.carousel.next();
        },
        afterPageChange(page) {
            this.sliderPageIndex = page;
        }
    },
    computed: {
        currentPage() {
            if (this.sliderPageIndex == 0) {
                return 1;
            } else {
                return this.sliderPageIndex / this.carouselSettings.slidesToScroll + 1;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.current-slide {
    opacity: 1;
    transition: all .5s;
}
.op {
    opacity: 0.5;
    transition: all .5s;
}
.advantages {
    margin-top: 110px;
    margin-bottom: 110px;
    @media (max-width: 480px) {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    &-container {
        width: calc(100% - 300px);
        margin: 0 auto;
        color: white;
        @media (max-width: 1024px) {
            width: calc(100% - 200px);
        }
        @media (max-width: 800px) {
            width: 100%;
        }
        &-header {
            font-size: 50px;
            line-height: 62px;
            letter-spacing: 0.08em;
            margin-bottom: 30px;
            @media (max-width: 1600px) {
                font-size: 40px;
                line-height: 50px;
            }
            @media (max-width: 480px) {
                font-size: 18px;
                line-height: 22px;
                letter-spacing: 0.08em;
            }
            span {
                background: linear-gradient(90.02deg, #FB37FF -0.5%, #58FFF5 121.25%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                font-family:'EncodeSans';
                font-weight: 800;
            }
        }
        .adv-slide {
            font-size: 12.0405px;
            line-height: 15px;
            text-align: center;
            &-text {
                background: linear-gradient(101.37deg, #07A6B4 1.6%, #434061 61.06%, #200628 113.51%);
                opacity: 1;
                border-radius: 20px;
                margin: 20px;
                padding: 20px;
                transition: all .5s;
                mix-blend-mode: hard-light;
                box-shadow: inset rgba(255, 255, 255, 0.5);
                filter: drop-shadow(0px 0px 5px #990099) drop-shadow(0px 5.6875px 4.375px rgba(77, 0, 66, 0.7));
                cursor: pointer;
                &:hover {
                    margin-top: 10px;
                }
                @media (max-width: 480px) {
                    margin: 10px;
                    padding: 20px;
                }
            }
        }

        &-btn {
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
            width: 200px;
            margin: 30px auto 0 auto;
            @media (max-width: 480px) {
                margin-top: 10px;
                width: 110px;
            }
            img {
                cursor: pointer;
                @media (max-width: 480px) {
                    height: 19px;
                }
            }
        }
    }
}
</style>
