<template>
  <div class="content">

    <div class="bg"></div>
    <div class="container">
      <!-- <Menu />  -->
    </div>

    <Graphic />
    <Three />
    <Popular />
    <Commission />
    <Hit />
    <Media />
    <Footer />
  </div>
</template>

<script>
// import Menu from "@/components/Menu";
import Graphic from "@/components/Affiliates/Graphic";
import Three from "@/components/Affiliates/Three";
import Popular from "@/components/Affiliates/Popular";
import Commission from "@/components/Affiliates/Commission";
import Hit from "@/components/Affiliates/Hit";
import Media from "@/components/Affiliates/Media";
import Footer from "@/components/Footer";
export default {
  name: 'AffiliatesView',
  layout: 'basic',
  components: {
    //   Menu, Graphic
    Graphic, Three, Popular, Commission, Hit, Media, Footer,

  }
}

</script>

<style lang="scss" scoped >
.content {
  position: relative;
  overflow-x: hidden;
}

.bg {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  height: 100% !important;
  width: 100% !important;
  background: url("@/assets/images/Affiliates/bg.jpg") 0 0 / cover no-repeat !important;
}
</style>
