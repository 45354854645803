<template>
  <div class="container-padding how-to__container">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="how-to__wrap">

          <h4 class="how-to__title text-gradient">{{ $t('how_to_start_title') }}</h4>
          <p class="how-to__text">{{ $t('how_to_start_text') }}</p>
          <img src="@/assets/img/crypto-phone.png" alt="" class="img-fluid">
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="how-to-step__container offset-md-1">
          <div class="how-to-step__wrap">
            <span class="how-to-step__number">1</span>
            <p class="how-to-step__text">{{ $t('sign_now') }}</p>
          </div>
          <div class="how-to-step__wrap">
            <span class="how-to-step__number">2</span>
            <p class="how-to-step__text" v-html="$t('how_to_start_text2')">
                <!-- {{ $t('how_to_start_text2') }} -->
            </p>
          </div>
          <div class="how-to-step__wrap">
            <span class="how-to-step__number">3</span>
            <p class="how-to-step__text">{{ $t('make_deposit') }}</p>
          </div>
          <div class="how-to-step__wrap">
            <span class="how-to-step__number">4</span>
            <p class="how-to-step__text" v-html="$t('choose_payment')"></p>
          </div>
          <div class="how-to-step__wrap">
            <span class="how-to-step__number">5</span>
            <p class="how-to-step__text">{{ $t('start_trading_session') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HowTo"
}
</script>

<style lang="scss" scoped>
.how-to-step__container {

}
.how-to-step__wrap {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  &:not(:last-child) {
    padding-bottom: 100px;
    &:after {
      content: '';
      position: absolute;
      top: 46px;
      left: 22px;
      background-color: #FC36ED;
      height: calc(100% - 46px);
      width: 1px;
      z-index: 1;
    }
  }
  &:first-child, &:nth-child(3) {
    &:after {
      height: calc(100% - 38px);
    }
  }
}
.how-to-step__number {
  position: relative;
  font-size: 16px;
  width: 45px;
  height: 45px;
  background: #525253;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}
.how-to-step__text {
  font-size: 20px;
  color: #fff;
  margin-left: 50px;
  margin-bottom: 0;
}
.how-to__wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.how-to__title {
  font-size: 50px;
  margin-bottom: 30px;
  font-weight: 600;
}
.how-to__text {
  font-size: 20px;
  max-width: 590px;
  color: #fff;
  margin-bottom: 40px;
}
</style>
