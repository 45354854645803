<template>
    <div>
        <Banner />
        <div class="global__bg">
            <Straight />
            <WhatToTrade />
            <Cards />
            <OurPlatform />
            <Advantages />
            <Footer />
        </div>
    </div>
</template>

<script>
import Banner from '@/components/Tools/Banner.vue';
import Straight from '@/components/Tools/Straight.vue';
import WhatToTrade from '@/components/Tools/WhatToTrade.vue';
import Cards from '@/components/Tools/Cards.vue';
import OurPlatform from '@/components/Tools/OurPlatform.vue';
import Advantages from '@/components/Tools/Advantages.vue';
import Footer from '@/components/Footer.vue';

export default {
    name: "ToolsView",
    layout: "basic",
    components: {
        Banner,
        Straight,
        WhatToTrade,
        Cards,
        OurPlatform,
        Advantages,
        Footer,
    },
};
</script>

<style lang="scss" scoped>
// .page {
//     background-color: black;
//     color: white;
// }
.global__bg {
    background-image: url("@/assets/img/background.png");
    background-color: #000;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>