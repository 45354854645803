<template>
  <div class="container-padding about-affiliates">
    <div class="about-affiliates__wrap">
      <div class="about-affiliates__width">
        <h4 class="about-affiliates__title">{{ $t('about_affiliates_title') }}</h4>
        <p class="about-affiliates__text">{{ $t('about_affiliates_text1') }}</p>
        <p class="about-affiliates__text">{{ $t('about_affiliates_text2')}}</p>
      </div>
    </div>
    <div class="about-affiliates__wrap about-affiliates-second__wrap">
      <div class="offset-md-3 about-affiliates__width img d-flex">
        <div class="d-flex flex-column">
          <h4 class="about-affiliates__title">{{ $t('utip_platform_title') }}</h4>
          <p class="about-affiliates__text">{{ $t('utip_platform_text') }}</p>
        </div>
        <div class="d-none flex-column align-items-center justify-content-center offset-md-2 d-md-flex">
          <div class="d-flex align-items-center mb-4">
            <a href="" class="app-download">
              <img src="@/assets/img/google-play-download.png" alt="" class="img-fluid">
            </a>
          </div>
          <div class="d-flex  align-items-center">
            <a href="" class="app-download">
              <img src="@/assets/img/app-store-download.png" alt="" class="img-fluid">
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="about-affiliates__wrap about-affiliates-third__wrap">
      <div class="about-affiliates__width">
        <h4 class="about-affiliates__title">{{ $t('about_affiliates_item1') }}</h4>
        <p class="about-affiliates__text" v-html="$t('about_affiliates_item2')"></p>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center justify-content-center mt-5 d-md-none">
      <div class="d-flex align-items-center mb-4">
        <a href="" class="app-download">
          <img src="@/assets/img/google-play-download.png" alt="" class="img-fluid">
        </a>
      </div>
      <div class="d-flex  align-items-center">
        <a href="" class="app-download">
          <img src="@/assets/img/app-store-download.png" alt="" class="img-fluid">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutAffiliates"
}
</script>

<style lang="scss" scoped>
.about-affiliates {
  padding-top: 135px;
  background-repeat: no-repeat;
  padding-bottom: 110px;
}

.about-affiliates-second__wrap {
  padding-top: 30px;
  .about-affiliates__width {
    max-width: 100%;
    > .d-flex.flex-column {
      max-width: 745px;
    }
  }
}

.about-affiliates-third__wrap {
  .about-affiliates__width {
    max-width: 100%;
    .about-affiliates__text {
      max-width: 800px;
    }
  }
}

.about-affiliates__title {
  font-size: 50px;
  color: #fff;
  padding-bottom: 25px;
}
.about-affiliates__text {
  font-size: 20px;
  color: #fff;
  padding-right: 20px;
  margin-bottom: 0;
}
.about-affiliates__width {
  max-width: 765px;
  position: relative;
  padding-bottom: 25px;
  margin-bottom: 25px;
  &:after {
    //content: '';
    //position: absolute;
    //top: 0;
    //right: 0;
    //display: block;
    //border: 3px solid rgba(251, 55, 255, 0.2);
    //border-top: none;
    //border-left: none;
    //border-bottom-right-radius: 50px;
    //height: 100%;
    //width: 50%;
  }
}
</style>
