<template>
    <div class="cards">
        <div class="main-wr cards-container">
            <div class="cards">
                <div class="cards-container-card ta-center">
                    <img src="@/assets/images/Tools/card_01.png" alt="" />
                    <div class="cards-container-card-heading">
                      {{ $t('tools.card_title_1')}}
                    </div>
                    <div class="cards-container-card-text t-light">
                        {{ $t('tools.card_text_1') }}
                    </div>
                </div>
                <div class="cards-container-card ta-center scnd">
                    <img src="@/assets/images/Tools/card_02.png" alt="" />
                    <div class="cards-container-card-heading">
                        {{ $t('tools.card_title_2') }}
                    </div>
                    <div class="cards-container-card-text t-light">
                        {{ $t('tools.card_text_2') }}
                    </div>
                </div>
                <div class="cards-container-card ta-center">
                    <img src="@/assets/images/Tools/card_03.png" alt="" />
                    <div class="cards-container-card-heading">
                      {{ $t('tools.card_title_3') }}
                    </div>
                    <div class="cards-container-card-text t-light">
                        {{ $t('tools.card_text_3') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Cards',
}
</script>

<style lang="scss" scoped>
.cards {
    margin-top: 150px;
    @media (max-width: 1600px) {
        margin-top: 90px;
    }
    @media (max-width: 480px) {
        margin-top: 50px;
    }
    &-container {
        .cards {
            display: flex;
            margin: 0 auto 0 auto;
            justify-content: space-between;
            width: calc(400px * 3 + 20px * 2);
            @media (max-width: 1600px) {
                width: 940px; // (card-width)*3 + margin*2
            }
            @media (max-width: 1200px) {
                width: auto;
            }
            @media (max-width: 800px) {
                display: block;
            }
        }
        &-card {
            width: 400px;
            @media (max-width: 1600px) {
                width: 300px;
            }
            @media (max-width: 1200px) {
                width: auto;
            }
            @media (max-width: 800px) {
                width: 50%;
                margin: 45px auto 0 auto;
            }
            @media (max-width: 480px) {
                width: 250px;
            }
            img {
                @media (max-width: 1600px) {
                    height: 87px;
                }
                @media (max-width: 480px) {
                    height: 56px;
                }
            }
            &-heading {
                font-size: 25px;
                line-height: 31px;
                margin: 30px 0;
                font-weight: 600;
                @media (max-width: 1600px) {
                    margin: 25px 0;
                    font-size: 18px;
                    line-height: 22px;
                }
                @media (max-width: 800px) {
                    margin: 20px 0;
                }
                @media (max-width: 480px) {
                    font-size: 13px;
                    line-height: 16px;
                    margin: 15px 0;
                }
            }
            &-text {
                font-size: 25px;
                line-height: 31px;
                @media (max-width: 1600px) {
                    font-size: 18px;
                    line-height: 22px;
                }
                @media (max-width: 480px) {
                    font-size: 12px;
                    line-height: 15px;
                }
            }
        }
        .scnd {
            margin-top: 210px;
            @media (max-width: 1600px) {
                margin-top: 200px;
            }
            @media (max-width: 800px) {
                margin-top: 45px;
            }
        }
    }
}
</style>
