<template>
    <div>
        <div class="global__bg">
            <WhyUs />
            <TradersAround />
            <HowTo />
            <FormRequest />
            <Footer />
        </div>
    </div>
</template>

<script>
import WhyUs from '@/components/AboutUs/WhyUs.vue';
import TradersAround from '@/components/AboutUs/TradersAround.vue';
import Footer from '@/components/Footer.vue';
import HowTo from '@/components/index/HowTo.vue';
import FormRequest from '@/components/index/FormRequest.vue';
export default {
    name: 'AboutUsView',
    layout: 'basic',
    components: {
    WhyUs,
    TradersAround,
    HowTo,
    Footer,
    FormRequest
},
};
</script>

<style lang="scss" scoped>
.global__bg {
  background-image: url("@/assets/images/AboutUs/bg.jpg");
  background-color: #000;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;

  overflow: hidden;
}
</style>