import { render, staticRenderFns } from "./WhatToTrade.vue?vue&type=template&id=25e32ba5&scoped=true&"
import script from "./WhatToTrade.vue?vue&type=script&lang=js&"
export * from "./WhatToTrade.vue?vue&type=script&lang=js&"
import style0 from "./WhatToTrade.vue?vue&type=style&index=0&id=25e32ba5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25e32ba5",
  null
  
)

export default component.exports