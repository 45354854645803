<template>
  <div class="popular">
    <div class="container">
      <div class="popular__body">
        <div class="popular__title" v-html="$t('affiliates.popular_title')"></div>
        <div class="popular-row">
          <div class="popular-row-left">
            <div class="popular-row-left__wrapper">
              <div
                class="popular-row-left-tab"
                @click="tabNumber = 0"
                v-bind:class="{ active: tabNumber === 0 }"
              >
                <div class="popular-row-left-tab__number">1</div>
                <div class="popular-row-left-tab__text">
                  {{ $t('affiliates.spoilers_item_title_1') }}
                </div>
              </div>
              <div
                class="popular-row-left-tab"
                @click="tabNumber = 1"
                v-bind:class="{ active: tabNumber === 1 }"
              >
                <div class="popular-row-left-tab__number">2</div>
                <div class="popular-row-left-tab__text">
                  {{ $t('affiliates.spoilers_item_title_2') }}
                </div>
              </div>
              <div
                class="popular-row-left-tab"
                @click="tabNumber = 2"
                v-bind:class="{ active: tabNumber === 2 }"
              >
                <div class="popular-row-left-tab__number">3</div>
                <div class="popular-row-left-tab__text">
                  {{ `${$t('affiliates.spoilers_item_title_3')}` }}
                </div>
              </div>
            </div>
          </div>
          <div class="popular-row__right">
            {{ `${$t(texts[tabNumber])}` }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Popular",
  layout: "basic",
  components: {},
  data() {
    return {
      tabNumber: 0,
      texts: [
        `affiliates.spoilers_item_text_1`,
        `affiliates.spoilers_item_text_2_1`,
        `affiliates.spoilers_item_text_3`,
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.popular {
  position: relative;
  &:after {
    content: "";
    display: "inline-block";
    right: 0px;
    top: 50px;
    position: absolute;
    width: 436px;
    height: 537px;
    background: url("@/assets/images/Affiliates/3/1.png") 0 0 / cover no-repeat;
    @media (max-width: $md1+px) {
      width: 337px;
      height: 444px;
      right: 50px;
    }
    @media (max-width: $md2+px) {
      width: 287px;
      height: 394px;
    }
    @media (max-width: $md3+px) {
      display: none;
    }
  }
  &__title::v-deep  {
    margin-bottom: 66px;
    font-weight: 400;
    font-size: 50px;
    line-height: 1.2;
    letter-spacing: 0.08em;
    color: #ffffff;
    max-width: 900px;
    @media (max-width: $md1+px) {
      font-size: 40px;
      margin-bottom: 45px;
    }
    @media (max-width: $md2+px) {
      font-size: 35px;
      margin-bottom: 40px;
    }
    @media (max-width: $md3+px) {
      font-size: 30px;
      margin-bottom: 35px;
    }
    @media (max-width: $md4+px) {
      font-size: 25px;
      margin-bottom: 30px;
    }
    @media (max-width: $md5+px) {
      font-size: 20px;
      margin-bottom: 25px;
    }
    @media (max-width: $md6+px) {
      font-size: 18px;
      margin-bottom: 20px;
    }
    span {
      background-image: linear-gradient(90deg, #58fff5, #82c9e8, #b587d9);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
.popular-row {
  display: flex;
  @media (max-width: $md3+px) {
    flex-wrap: wrap;
    margin-bottom: 80px;
  }
  @media (max-width: $md5+px) {
    margin-bottom: 70px;
  }
  @media (max-width: $md6+px) {
    margin-bottom: 50px;
  }
  &__right {
    flex: 0 1 548px;
    font-size: 20px;
    line-height: 1.2;
    font-family: "EncodeSansLight";
    color: #ffffff;
    @media (max-width: $md1+px) {
      flex: 0 1 419px;
      font-size: 16px;
    }
    @media (max-width: $md3+px) {
      flex: 0 1 100%;
      margin-top: 30px;
      font-size: 13px;
    }
  }
}
.popular-row-left {
  margin-right: 170px;
  @media (max-width: $md1+px) {
    margin-right: 130px;
  }
  @media (max-width: $md2+px) {
    margin-right: 40px;
  }
  @media (max-width: $md3+px) {
    flex: 0 1 100%;
    margin-right: 0;
    overflow: auto;
    &::-webkit-scrollbar {
      height: 5px;
      background-color: #828186;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #86c4e7;
      border-radius: 20px;
    }
  }
  &__wrapper {
    @media (max-width: $md3+px) {
      display: flex;
    }
  }
}
.popular-row-left-tab {
  @media (max-width: $md3+px) {
    flex: 1 0 385px;
    margin-right: 19px;
    &:last-child {
      margin-right: 0;
    }
  }
  @media (max-width: $md5+px) {
    flex: 1 0 262px;
    height: 58px;
  }
  background-color: #140f15;
  width: 385px;
  height: 85px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding: 14px;
  border-left: 1px solid #140f15;
  cursor: pointer;
  &.active {
    border-left: 1px solid #ff83b7;
    .popular-row-left-tab__number {
      background: linear-gradient(180deg, #58fff5 0%, #ff26c2 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    .popular-row-left-tab__text {
      color: #e24dcb;
    }
  }
  &__number {
    font-size: 70px;
    color: #ffffff;
    margin-right: 30px;
    @media (max-width: $md5+px) {
      font-size: 47px;
    }
  }

  &__text {
    font-size: 20px;
    line-height: 1.2;
    color: #ffffff;
    font-family: "EncodeSansLight";
    @media (max-width: $md5+px) {
      font-size: 13px;
      max-width: 170px;
    }
  }
}
</style>
