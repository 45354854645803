<template>
    <div class="traders">
        <div class="main-wr traders-container">
            <div class="traders-container-content">
                <div class="traders-container-content-header" v-html="$t('about.traders_around_title')"></div>
                <div class="traders-container-content-text t-light">
                    <div>
                        {{ $t('about.traders_around_text_1') }}
                    </div>
                    <div class="par">
                        {{ $t('about.traders_around_text_2') }}
                    </div>
                </div>
            </div>
            <div class="tr-planet">
                <img src="@/assets/images/AboutUs/traders_planet.png" alt="" />
            </div>
        </div>
        <div class="main-wr traders-cards">
            <div class="traders-cards-content">
                <div class="traders-cards-content-card ta-center">
                    <img src="@/assets/images/AboutUs/fast_support.png" alt="" />
                    <div class="traders-cards-content-card-text">{{ $t('about.traders_around_item_1') }}</div>
                </div>
                <div class="traders-cards-content-card ta-center">
                    <img src="@/assets/images/AboutUs/fast_with.png" alt="" />
                    <div class="traders-cards-content-card-text">{{ $t('about.traders_around_item_2') }}</div>
                </div>
                <div class="traders-cards-content-card ta-center">
                    <img src="@/assets/images/AboutUs/fast_exec.png" alt="" />
                    <div class="traders-cards-content-card-text">{{ $t('about.traders_around_item_3') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TradersAround',
}
</script>

<style lang="scss" scoped>
.traders {
    margin-top: 110px;
    margin-bottom: 110px;
    @media (max-width: 480px) {
        margin-top: 70px;
        margin-bottom: 110px;
    }
    &-container {
        display: flex;
        &-content {
            width: 50%;
            @media (max-width: 1200px) {
                width: 100%;
            }
            &-header::v-deep {
                font-size: 40px;
                line-height: 50px;
                letter-spacing: 0.08em;
                width: 720px;
                @media (max-width: 1600px) {
                    font-size: 30px;
                    line-height: normal;
                    width: 550px;
                }
                @media (max-width: 700px) {
                    width: 100%;
                }
                @media (max-width: 480px) {
                    font-size: 18px;
                    line-height: 22px;
                    letter-spacing: 0.08em;
                }
                span {
                    background: linear-gradient(90.02deg, #FB37FF -0.5%, #58FFF5 121.25%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                    font-family:'EncodeSans';
                    font-weight: 800;
                }
            }
            &-text {
                margin-top: 50px;
                font-size: 18px;
                line-height: 22px;
                width: 641px;
                @media (max-width: 1600px) {
                    font-size: 16px;
                    line-height: normal;
                    width: 550px;
                }
                @media (max-width: 1200px) {
                    width: 80%;
                }
                @media (max-width: 1024px) {
                    margin-top: 30px;
                }
                @media (max-width: 700px) {
                    width: 100%;
                }
                @media (max-width: 480px) {
                    font-size: 13px;
                    line-height: 16px;
                    margin-top: 20px;
                }
                .par {
                    margin-top: 30px;
                    @media (max-width: 1200px) {
                        margin-top: 15px;
                    }
                }
            }
        }
        .tr-planet {
            width: 50%;
            @media (max-width: 1200px) {
                display: none;
            }
            img {
                margin-left: auto;
                display: flex;
                @media (max-width: 1800px) {
                    width: 90%;
                    margin-top: 100px;
                }
                @media (max-width: 1600px) {
                    margin-bottom: auto;
                }
                @media (max-width: 1400px) {
                    width: 80%;
                }
            }
        }
    }
    &-cards {
        margin-top: 70px;
        @media (max-width: 480px) {
            margin-top: 38px;
        }
        &-content {
            display: flex;
            margin: 0 auto;
            justify-content: space-between;
            width: 1100px;
            @media (max-width: 1400px) {
                width: 800px;
            }
            @media (max-width: 1100px) {
                width: 600px;
            }
            @media (max-width: 720px) {
                width: 100%;
            }
            &-card {
                &:hover img {
                    scale: 1.1;
                }
                img {
                    height: 140px;
                    transition: all 0.2s;
                    @media (max-width: 1400px) {
                        height: 100px;
                    }
                    @media (max-width: 1100px) {
                        height: 70px;
                    }
                    @media (max-width: 720px) {
                        height: 55px;
                    }
                }
                &-text {
                    font-weight: 600;
                    font-size: 25px;
                    line-height: 31px;
                    letter-spacing: 0.08em;
                    margin-top: 25px;
                    @media (max-width: 1400px) {
                        font-size: 20px;
                        line-height: normal;
                    }
                    @media (max-width: 1100px) {
                        font-size: 16px;
                    }
                    @media (max-width: 720px) {
                        font-size: 12px;
                        width: 95px;
                    }
                }
            }
        }
    }
}
</style>
