<template>
    <div class="straight">
        <div class="main-wr straight-container">
            <div class="side">
                <div class="straight-container-header" v-html="$t('tools.straight_title')"></div>
                <div class="straight-container-text t-light">
                    <div>
                        {{ $t('tools.straight_text_1') }}
                    </div>
                    <div class="par">
                        {{ $t('tools.straight_text_2') }}
                    </div>
                    <div class="par">
                        {{ $t('tools.straight_text_3') }}
                    </div>
                </div>
            </div>
            <div class="side">
                <img src="@/assets/images/Tools/straight.png" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Straight',
}
</script>

<style lang='scss' scoped>
.straight {
    &-container {
        display: flex;
        justify-content: space-between;
        padding-top: 100px;

        @media (max-width: 1024px) {
            display: block;
        }

        @media (max-width: 480px) {
            padding-top: 70px;
        }

        &-header {
            color: #58FFF5;
            font-size: 50px;
            line-height: 62px;
            letter-spacing: 0.08em;
            width: 70vw;

            @media (max-width: 1600px) {
                font-size: 40px;
                line-height: 50px;
            }

            @media (max-width: 1024px) {
                width: 100%;
            }

            @media (max-width: 480px) {
                font-size: 18px;
                line-height: 22px;
                letter-spacing: 0.08em;
            }
        }

        &-text {
            margin-top: 50px;
            font-size: 25px;
            line-height: 31px;

            @media (max-width: 1600px) {
                font-size: 18px;
                line-height: 22px;
            }

            @media (max-width: 1024px) {
                margin-top: 30px;
            }

            @media (max-width: 480px) {
                font-size: 13px;
                line-height: 16px;
            }

            .par {
                margin-top: 30px;

                @media (max-width: 1024px) {
                    margin-top: 10px;
                }

                @media (max-width: 480px) {
                    margin-top: 15px;
                }
            }
        }

        .side {
            width: 50%;

            @media (max-width: 1024px) {
                width: 100%;
            }
        }

        img {
            &:hover {
                transform: rotate3d(1, 1, 0, 40deg);
            }

            transition: all .5s;
            margin-top: 70px;
            margin-left: auto;
            display: flex;
            margin-right: 100px;

            @media (max-width: 1600px) {
                height: 380px;
                margin-top: 0;
                margin-right: 50px;
            }

            @media (max-width: 1400px) {
                margin-right: 0;
                margin-top: 100px;
                height: 330px;
            }

            @media (max-width: 1024px) {
                margin: 30px auto 0 auto;
                height: 360px;
            }

            @media (max-width: 480px) {
                height: 251px;
                margin-top: 50px;
            }
        }
    }
}
</style>
