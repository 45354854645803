<template>
  <div>
    <transition name="bounce">
      <child/>
    </transition>
  </div>
</template>

<script>
export default {

}
</script>
