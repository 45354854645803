<template>
    <div>
        <div class="global__bg">
            <Banner />
            <CurrentCurrency />
            <Footer />
        </div>
    </div>
</template>

<script>
import Banner from '@/components/CompanySupport/Banner.vue';
import CurrentCurrency from '@/components/CompanySupport/CurrentCurrency.vue';
import Footer from '@/components/Footer.vue';
export default {
    name: 'CompanySupportView',
    layout: 'basic',
    components: {
        Banner,
        CurrentCurrency,
        Footer,
    },
};
</script>

<style lang="scss" scoped>
.global__bg {
  background-image: url("@/assets/img/background.png");
  background-color: #000;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>