<template>
  <main>
    <Banner />
    <div class="global__bg">
      <Benefit />
      <TradeBenefits />
      <Map />
      <OnlineForex />
      <TradeWithEagle />
      <AboutAffiliates />
      <HowTo />
      <FormRequest />
      <Footer />
    </div>
  </main>
</template>

<script>
/* eslint-disable */
import Banner from "@/components/index/Banner";
import Benefit from "@/components/index/Benefit";
import TradeBenefits from "@/components/index/TradeBenefits";
import OnlineForex from "@/components/index/OnlineForex";
import Map from "@/components/index/Map";
import TradeWithEagle from "@/components/index/TradeWithEagle";
import AboutAffiliates from "@/components/index/AboutAffiliates";
import HowTo from "@/components/index/HowTo";
import FormRequest from "@/components/index/FormRequest";
import Footer from "@/components/Footer";
export default {
  name: 'HomeView',
  layout: 'basic',
  components: {
    Banner,
    Benefit,
    TradeBenefits,
    OnlineForex,
    Map,
    TradeWithEagle,
    AboutAffiliates,
    HowTo,
    FormRequest,
    Footer,
  }
}
</script>

<style lang="scss" scoped>
.global__bg {
  background-image: url("@/assets/img/background.png");
  background-color: #000;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
